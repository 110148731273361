import cx from 'classnames'
import { useState, Fragment, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Accordion } from "react-bootstrap"
import { FaSearch } from 'react-icons/fa'

import GFDetailsCard from '../gf-details-card/GFDetailsCard'
import GFDetailsPanel from '../gf-details-panel/GFDetailsPanel'
import ConfirmationModalLight, { VARIANTS as ConfirmationModalVariants } from '../../../../shared/modals/InformativeModal/ConfirmationModalLight'
import InformativeModalLight, { VARIANTS as InformativeModalVariants } from '../../../../shared/modals/InformativeModal/InformativeModalLight'

import styles from './GFList.module.css'
import geofenceAction from '../../../../../store/actions/geofence'


const GFList = (props) => {
    const { fenceData, filters, filterData, fenceTypeIconMap, onFilterChange, groupList, refreshGeofences } = props
    const [filterExpanded, setFilterExpanded] = useState(false)
    const [selectedFence, setSelectedFence] = useState(null)
    const [geofenceDeleteModal, setGeofenceDeleteModal] = useState({
        isConfirmationOpen: false,
        fenceId: null,
        fenceName: '',
        isInformativeOpen: false,
        informativeMessage: '',
        informativeVariant: ''
    })
    const navigate = useNavigate()
    const filterContainerRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterContainerRef.current && !filterContainerRef.current.contains(event.target)) {
                setSelectedFence(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        };
    }, [setSelectedFence])

    const handleCreateFenceClick = () => {
        navigate('/geo-fence/create')
    }

    const handleEditFenceClick = (event, fenceID) => {
        let url = `/geo-fence/edit/${fenceID}`
        if (event) {
            url += `?action=${event}`
        }
        navigate(url)
    }

    const handleFilterToggle = (toggleValue) => {
        setFilterExpanded([true, false].includes(toggleValue) ? toggleValue : !filterExpanded)
    }

    const handleCardClick = (fenceDetails) => {
        if (selectedFence && fenceDetails._id === selectedFence._id) {
            setSelectedFence(null)
        } else {
            setSelectedFence(fenceDetails)
        }
    }

    const handleDetailsCloseClick = () => {
        setSelectedFence(null)
    }

    const handleDeleteConfirmation = async (action) => {
        if (action === 'CANCEL') {
            setGeofenceDeleteModal(prevState => ({ ...prevState, isConfirmationOpen: false, fenceId: null, fenceName: '' }))
            return;
        }

        const result = await geofenceAction.deleteGeofence({ fenceId: geofenceDeleteModal.fenceId })
        
        if (result.status !== 200) {
            setGeofenceDeleteModal(prevState => ({
                ...prevState,
                isConfirmationOpen: false,
                fenceId: null,
                fenceName: '',
                isInformativeOpen: true,
                informativeMessage: result.data.error,
                informativeVariant: InformativeModalVariants.ERROR
            }))
            return;
        }
        
        await refreshGeofences();
        setGeofenceDeleteModal(prevState => ({
            ...prevState,
            isConfirmationOpen: false,
            fenceId: null,
            fenceName: '',
            isInformativeOpen: true,
            informativeMessage: 'Geofence deleted',
            informativeVariant: InformativeModalVariants.SUCCESS
        }))
    }

    const handleDeleteInformativeClose = () => {
        setGeofenceDeleteModal(prevState => ({ ...prevState, isInformativeOpen: false, informativeMessage: '', informativeVariant: '' }))
    }

    return (
        <Fragment>
            <div className={styles.panelContainer}>
                <div className={styles.panelHeader}>
                    <div className={`${styles.panelHeaderRow} mb-3`}>
                        <p className={styles.panelHeaderTitle}>Dashboard</p>

                        <button
                            className={styles.panelButton}
                            onClick={handleCreateFenceClick}
                        >
                            Create
                        </button>
                    </div>

                    <div className={styles.panelHeaderRow}>
                        <div className={styles.panelSearchWrapper}>
                            <FaSearch className={styles.panelSearchIcon} />
                            <input
                                type="text"
                                className={styles.panelSearchInput}
                                placeholder='Search'
                                onChange={(e) => onFilterChange({ name: 'searchText', value: e.target.value })}
                                value={filterData.searchText}
                            />
                        </div>

                        <div className={styles.filterWrapper}>
                            <button className={styles.panelIconButton} onClick={handleFilterToggle}>
                                <img src="/images/svgicon/filter.svg" alt="filter icon" />
                            </button>

                            <div className={cx(styles.filterContainer, { [styles.filterContainerExpanded]: filterExpanded })}>
                                <Accordion>
                                    {filters.map((filter, filterI) => (
                                        <Fragment key={`filter_${filter.name}`}>
                                            <Accordion.Item className={styles.accordionItem} eventKey={filterI}>
                                                <Accordion.Header className={styles.accordionHeader}>{filter.label}</Accordion.Header>
                                                <Accordion.Body className={styles.accordionBody}>
                                                    {filter.options.map((data) => (
                                                        <button
                                                            className={styles.filterCheckbox}
                                                            onClick={() => onFilterChange({ name: filter.name, value: data.value })}
                                                        >
                                                            <img src={filterData[filter.name].includes(data.value) ? '/images/svgicon/checkbox_checked.svg' : '/images/svgicon/checkbox_unchecked.svg'} alt='checkbox icon' />
                                                            <span>{data.label}</span>
                                                        </button>
                                                    ))}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            {filterI < filters.length - 1 && <hr className='m-1' />}
                                        </Fragment>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.panelBody}>
                    <div className={styles.fenceCardsWrapper}>
                        {fenceData.map((fence, fenceI) => (
                            <Fragment key={`fence_card_${fenceI}`}>
                                <GFDetailsCard
                                    fenceDetails={fence}
                                    fenceTypeIconMap={fenceTypeIconMap}
                                    onCardClick={handleCardClick}
                                    isSelected={selectedFence && selectedFence._id === fence._id}
                                    handleEditClick={(event) => handleEditFenceClick(event, fence._id)}
                                    groupList={groupList}
                                />
                                {fenceI < fenceData.length - 1 && <hr className={styles.fenceCardDivider} />}
                            </Fragment>
                        ))}
                    </div>
                </div>
            </div>

            <GFDetailsPanel
                fenceDetails={selectedFence}
                fenceTypeIconMap={fenceTypeIconMap}
                onCloseClick={handleDetailsCloseClick}
                onEditFenceClick={() => handleEditFenceClick('CONFIGURE_ALERT', selectedFence._id)}
                onDeleteFenceClick={() => setGeofenceDeleteModal(prevState => ({ ...prevState, fenceId: selectedFence._id, fenceName: selectedFence.fence_name, isConfirmationOpen: true }))}
            />

            <ConfirmationModalLight
                isOpen={geofenceDeleteModal.isConfirmationOpen}
                onCancel={() => handleDeleteConfirmation('CANCEL')}
                onConfirm={() => handleDeleteConfirmation('CONFIRM')}
                message={`Are you sure you want to delete geofence ${geofenceDeleteModal.fenceName} ?`}
                variant={ConfirmationModalVariants.WARNING}
            />

            <InformativeModalLight
                isOpen={geofenceDeleteModal.isInformativeOpen}
                onClose={handleDeleteInformativeClose}
                message={geofenceDeleteModal.informativeMessage}
                variant={geofenceDeleteModal.informativeVariant}
            />
        </Fragment>
    )
}

export default GFList