import React, { useEffect, useState } from "react";
import PropTypes from "prop-types"
import styles from "./InformativeModal.module.css";

export const variantImages = {
  success: '/images/svgicon/promptSuccess.svg',
  error: '/images/svgicon/decline.svg',
  warning: '/images/svgicon/warning.svg',
}

export const VARIANTS = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
}

const ConfirmationModalLight = ({ isOpen, onCancel, onConfirm, variant, message }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setIsModalOpen(true);
    }
  }, [isOpen]);

  const handleModalCancel = () => {
    setIsModalOpen(false)
    onCancel()
  }

  const handleModalConfirm = () => {
    setIsModalOpen(false)
    onConfirm()
  }

  if (!isModalOpen) {
    return null;
  }

  return (
    <div className={`${styles.modalContainer} ${styles.lightModalContainer} ${styles.confirmationModalContainer}`}>
      <div className={`${styles.modalWrapper}`}>
          <div className={`${styles.modalCloseWrapper}`}>
            <img
                className={`${styles.lightThemeModalCrossIcon}`}
                onClick={handleModalCancel}
                src='/images/geofenceModuleIcon/closeBtn.svg'
                alt='close-btn'
            />
        </div>
        <div className={`${styles.content}`}>
          <img className={styles.statusIcon} src={variantImages[variant]} alt={variant} />
          <div className={styles.message}>{message}</div>
        </div>
        <div className={styles.footer}>
            <button className={styles.panelButton} onClick={handleModalConfirm}>Yes</button>
            <button className={styles.panelButton} onClick={handleModalCancel}>No</button>
        </div>
      </div>
    </div>
  );
};

ConfirmationModalLight.defaultProps = {
  variant: 'success',
  message: 'Success',
};

ConfirmationModalLight.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['success, error, warning']),
  message: PropTypes.string,
};

export default ConfirmationModalLight; 