
import { useEffect, useState } from 'react'
import cx from 'classnames'

import GFEditAlertDetails from '../gf-edit-alert-details/GFEditAlertDetails'
import GFEditAlertVehicles from '../gf-edit-alert-vehicles/GFEditAlertVehicles'

import styles from './GFAlertEdit.module.css'


const tabHeaders = [{
    label: 'Alert Details',
    value: 'ALERT_DETAILS'
}, {
    label: 'Vehicles',
    value: 'VEHICLES'
}]

const GFAlertEdit = (props) => {
    const { userEmail, alertDetails, alertType, userList, groupList, onClose, mappedOptionsByVehicle, onSave, selectedAssignVehicle, handleSelectVehicleChange, handleNextClick } = props
    const [activeTab, setActiveTab] = useState('ALERT_DETAILS')
    const [activeAlertDetails, setActiveAlertDetails] = useState(null)
    const [activeAlertError, setActiveAlertError] = useState({
        time: '',
        timeStart: '',
        timeEnd: '', 
    })
    const isEditAllowed = !alertDetails.configuredBy || alertDetails.configuredBy === userEmail
  
    
    useEffect(() => {
        if (alertDetails && userList.length) {
            const mappedAlertDetails = {...alertDetails}

            // mappedAlertDetails.notification_users = userList.filter(u => alertDetails.notification_users.map().includes(u.value))
            
            setActiveAlertDetails(mappedAlertDetails)
        }
    }, [alertDetails, userList])

    const handleSaveClick = () => {
        onSave(activeAlertDetails)
    }
    const handleTabChange = (value) => {
        if (!isEditAllowed) {
            return
        }
        setActiveTab(value)
    }

    if (!activeAlertDetails) {
        return <></>
    }

    return (
        <div className={styles.modalContainer}>
            <div className={styles.modalWrapper}>
                <div className={styles.modalContent}>
                    <button className={styles.modalCloseButton} onClick={onClose}>
                        <img src="/images/geofenceModuleIcon/closeBtn.svg" alt='close fence details panel icon' />
                    </button>

                    <div className={styles.tabsContainer}>
                        {tabHeaders.map(tab => (
                            <div
                                key={`edit_alert_${tab.value}`}
                                className={cx(styles.tab, { [styles.active]: tab.value === activeTab, [styles.tabDisabled]: !isEditAllowed && tab.value === 'VEHICLES' })}
                                onClick={() => handleTabChange(tab.value)}
                            >
                                {tab.label}
                            </div>
                        ))}
                    </div>

                    <div className={styles.tabsContent}>
                        {activeTab === 'ALERT_DETAILS' && (
                            <GFEditAlertDetails
                                alertDetails={activeAlertDetails}
                                setAlertDetails={setActiveAlertDetails}
                                alertError={activeAlertError}
                                setAlertError={setActiveAlertError}
                                userList={userList}
                                alertType={alertType}
                                handleSaveClick={handleSaveClick}
                                isEditAllowed={isEditAllowed}
                                userEmail={userEmail}
                            />
                        )}

                        {activeTab === 'VEHICLES' && (
                            <GFEditAlertVehicles
                                alertDetails={activeAlertDetails}
                                setAlertDetails={setActiveAlertDetails}
                                mappedOptionsByVehicle={mappedOptionsByVehicle}
                                groupList={groupList}

                                selectedAssignVehicle={selectedAssignVehicle}
                                handleSelectVehicleChange={handleSelectVehicleChange}
                                handleNextClick={handleNextClick}
                                handleSaveClick={handleSaveClick}
                            />
                        )}
                    </div>
                </div>

                {/* <div className={styles.modalFooter}>
                    <button className={styles.modalSaveButton} onClick={handleSaveClick}>Save</button>
                </div> */}
            </div>
        </div>
    )
}

export default GFAlertEdit;