import { useRef, useState, useCallback, useEffect } from "react"
import cx from 'classnames'

import GFMap from "./gf-map/GFMap"
import GFList from "./gf-list/GFLIst"
import withGeofenceHOC from "../geofenceHOC"

import styles from './GFDashboard.module.css'
import { fetchGroupList } from "../../../../store/actions/groups"

const defaultMapConfig = {
    center: [
        20.5937,
        78.9629,
    ],
    zoom: 5,
    attributionControl: false,
    maxZoom: 22,
    minZoom: 5,
    zoomControl: false,
    enableMarkerCluster: true,
    zoomControlPosition: 'topright'
}

const GFDashboard = (props) => {
    const { masters, fenceTypeIconMap, geofences, mappedOptionsByVehicle, refreshGeofences } = props
    const fenceOwnerNamesByGeofences = [...new Set(geofences.map(fence => fence.fence_identifiers.owner_name))]

    const mapRef = useRef(null)
    const [mapConfig, setMapConfig] = useState(defaultMapConfig)
    const [groupList, setGroupList] = useState([]);

    const [filterData, setFilterData] = useState({
        searchText: '',
        citySelect: [],
        ownerTypeSelect: [],
        ownerNameSelect: [],
        fenceTypeSelect: '',
    })

    useEffect(() => {
        const fetchGroupsList = async () => {
            const userId = JSON.parse(localStorage.getItem("user"))?._id;
            if (userId) {
                const result = await fetchGroupList(userId);
                setGroupList(result);
            }
        };
        fetchGroupsList()
        refreshGeofences()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleFilterChange = ({ name, value }) => {
        const newFilterData = { ...filterData }
        if (name === 'fenceTypeSelect') {
            if (newFilterData[name] === value) {
                newFilterData[name] = ''
            } else {
                newFilterData[name] = value
            }
        } else if (name === 'searchText') {
            newFilterData[name] = value
        } else {
            if (newFilterData[name].includes(value)) {
                newFilterData[name] = newFilterData[name].filter(d => d !== value)
            } else {
                newFilterData[name].push(value)
            }
        }

        setFilterData({ ...newFilterData })
    }

    const filterFences = () => {
        const {
            searchText,
            citySelect,
            ownerTypeSelect,
            ownerNameSelect,
            fenceTypeSelect
        } = filterData

        const filtersCount = Object.values(filterData).filter(v => v.length).length

        if (!filtersCount) {
            return geofences
        }

        return geofences.filter(fence => {
            let matchCount = 0

            if (searchText.length && fence.fence_name.toLowerCase().includes(searchText.toLowerCase())) {
                matchCount += 1
            }

            if (citySelect.length && citySelect.includes(fence.fence_identifiers.city)) {
                matchCount += 1
            }

            if (ownerTypeSelect.length && ownerTypeSelect.includes(fence.fence_identifiers.owner_type)) {
                matchCount += 1
            }

            if (ownerNameSelect.length && ownerNameSelect.includes(fence.fence_identifiers.owner_name)) {
                matchCount += 1
            }

            if (fenceTypeSelect.length && fenceTypeSelect.includes(fence.fence_identifiers.fence_type)) {
                matchCount += 1
            }

            return matchCount === filtersCount
        })
    }

    const prepareFilters = () => {
        const filters = [{
            name: 'citySelect',
            label: 'City',
            options: mappedOptionsByVehicle.location,
        }, {
            name: 'ownerTypeSelect',
            label: 'Fence Owner Type',
            options: masters.FENCE_OWNER_TYPE,
        }, {
            name: 'ownerNameSelect',
            label: 'Fence Owner Name',
            options: masters.FENCE_OWNER_NAME.filter(v => fenceOwnerNamesByGeofences.length && fenceOwnerNamesByGeofences.includes(v.value)).sort((a, b) => {
                if (a.value === 'OTHER') return 1
                if (b.value === 'OTHER') return -1
                return 0
            }),
        }]
        return filters
    }

    const handleZoomClick = useCallback((control) => {
        const currentZoom = mapRef.current.getZoom()
        if (control === 'ZOOM_IN') {
            mapRef.current.setZoom(currentZoom + 1)
            setMapConfig({ ...mapConfig, zoom: currentZoom + 1 })
        }
        if (control === 'ZOOM_OUT') {
            mapRef.current.setZoom(currentZoom - 1)
            setMapConfig({ ...mapConfig, zoom: currentZoom - 1 })
        }
    }, [mapConfig, mapRef])

    const handleResetClick = useCallback(() => {
        setMapConfig({ ...defaultMapConfig })

        setFilterData({
            searchText: '',
            citySelect: [],
            ownerTypeSelect: [],
            ownerNameSelect: [],
            fenceTypeSelect: '',
        })

        mapRef.current.setView(defaultMapConfig.center, defaultMapConfig.zoom)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapConfig, mapRef, defaultMapConfig])

    const filteredFences = filterFences()
    const filters = prepareFilters();

    return (
        <div className={styles.fenceDashboard}>
            <GFList refreshGeofences={refreshGeofences} groupList={groupList} masters={masters} fenceData={filteredFences} filters={filters} filterData={filterData} fenceTypeIconMap={fenceTypeIconMap} onFilterChange={handleFilterChange} />

            <div className={styles.fenceTypeFilterWrapper}>
                {masters.FENCE_TYPE.map((fenceType, fenceTypeI) => (
                    <button
                        key={`fence_type_${fenceTypeI}`}
                        className={cx(styles.fenceTypeFilterButton, { [styles.selected]: filterData.fenceTypeSelect === fenceType.value })}
                        onClick={() => handleFilterChange({ name: 'fenceTypeSelect', value: fenceType.value })}
                    >
                        <img src={fenceTypeIconMap[fenceType.value]} alt={fenceType.label} />
                        {fenceType.label}
                    </button>
                ))}
            </div>

            <GFMap mapConfig={mapConfig} setMapConfig={setMapConfig} mapRef={mapRef} fenceData={filteredFences} onZoomClick={handleZoomClick} onResetClick={handleResetClick} />
        </div>
    )
}

export default withGeofenceHOC(GFDashboard)