import React, { useEffect, useState } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import '../child-components/alert/geofanceAlert.css';
import {
	getGeoFanReports,
	getGeoFanceHistoryReports,
	GetGeoFanceCity,
	ExportGeoFenceAlertsReport,
	resetGeoFanceReport,
} from '../.../../../../store/actions/geo-fence/report';
import { GetSubsRnNums } from '../../../store/actions/vehicle/vehicle';
import { connect } from 'react-redux';
import RecentReport from '../child-components/alert/recentReport';
import HistoryReport from '../child-components/alert/historyReport';
import Loader from '../../helpers/hoc/loader/Loader';
import { mapper } from '../../../utils/mapper';

const GeofanceAlert = ({
	geoFanceReportData,
	getGeoFanReports,
	GetGeoFanceCity,
	geoFanceCityData,
	subcribedRnNums,
	GetSubsRnNums,
	geoFanceReportDataCount,
	geoFanceReportHistoryData,
	geoFanceReportHistoryDataCount,
	getGeoFanceHistoryReports,
	ExportGeoFenceAlertsReport,
	isLoading,
	masters,
	geofences,
	resetGeoFanceReport,
}) => {
	const [activeTab, setActiveTab] = useState('non_comm_report');
	const [startDateHistory, setStartDateHistory] = useState('');
	const [endDateHistory, setEndDateHistory] = useState('');
	const [fenceIds, setFenceIds] = useState([]);
	const [vehicleRnNumbers, setVehicleRnNumbers] = useState([]);
	const [fenceTypes, setFenceTypes] = useState([]);
	const [fenceOwnerTypes, setFenceOwnerTypes] = useState([]);
	const [fenceOwnerNames, setFenceOwnerNames] = useState([]);
	const [alertTypes, setAlertTypes] = useState([]);
	const [cities, setCities] = useState([]);
	const [page, setPage] = useState(1);
	const [radioSelected, setRadioSelected] = useState('location_filter');
	const ITEMS_PER_PAGE = 7;

	useEffect(() => {
		const fetchData = async () => {
			await getGeoFanReports({
				fromDate: new Date(new Date().setDate(new Date().getDate() - 1)),
				toDate: new Date(new Date().setTime(new Date().getTime() - 60000)),
				pageSize: ITEMS_PER_PAGE,
			});
		};

		fetchData();

		return () => {
			resetGeoFanceReport()
		}
	}, [getGeoFanReports, resetGeoFanceReport]);

	useEffect(() => {
		if (activeTab === 'non_comm_report') {
			if (geoFanceReportDataCount > 0) {
				getGeoFanReports({
					fromDate: new Date(new Date().setDate(new Date().getDate() - 1)),
					toDate: new Date(new Date().setTime(new Date().getTime() - 60000)),
					pageIndex: page - 1,
					pageSize: ITEMS_PER_PAGE,
				});
			}
		} else {
			if (geoFanceReportHistoryDataCount > 0) {
				getGeoFanceHistoryReports({
					fromDate: startDateHistory,
					toDate: endDateHistory,
					pageSize: ITEMS_PER_PAGE,
					pageIndex: page - 1,
					fenceIds: fenceIds.map((item) => item?._id),
					vehicleRnNumbers: vehicleRnNumbers.map((item) => item?.label),
					fenceTypes: fenceTypes.map((item) => item?.value),
					fenceOwnerTypes: fenceOwnerTypes.map((item) => item?.value),
					fenceOwnerNames: fenceOwnerNames.map((item) => item?.value),
					cities: cities.map((item) => item?.value),
					alertTypes: alertTypes.map((item) => item?.value),
				});
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	function onDateChangeHandler(value) {
		setStartDateHistory(value[0]);
		setEndDateHistory(value[1]);
	}

	function renderAlertTimeTooltip (props) {
		const { ingressDateTime, egressDateTime, alertType } = props

		return (
			<Tooltip className='tooltip' id='button-tooltip' {...props}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						backgroundColor: '#1f2a5d',
					}}
				>
					{alertType === 'Dwell Time' && <div className='geofance__alert__tooltip'>Ingress Date Time : {ingressDateTime}</div>}
					<div className='geofance__alert__tooltip'>Egress Date Time : {egressDateTime}</div>
				</div>
			</Tooltip>
		)
	}

	function renderTooltip(props) {
		const { fenceType, fenceOwnerType, fenceOwnerName } = props;

		return (
			<Tooltip className='tooltip' id='button-tooltip' {...props}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						backgroundColor: '#1f2a5d',
					}}
				>
					<div className='geofance__alert__tooltip'>Fence Type : {fenceType}</div>
					<div className='geofance__alert__tooltip'>Fence Owner Type : {fenceOwnerType}</div>
					<div className='geofance__alert__tooltip'>Fence Owner Name : {fenceOwnerName}</div>
				</div>
			</Tooltip>
		);
	}

	const onGetReportsClick = async () => {
		setPage(1);
		await getGeoFanceHistoryReports({
			fromDate: startDateHistory,
			toDate: endDateHistory,
			pageSize: ITEMS_PER_PAGE,
			pageIndex: 0,
			fenceIds: fenceIds.map((item) => item?._id),
			vehicleRnNumbers: vehicleRnNumbers.map((item) => item?.label),
			fenceTypes: fenceTypes.map((item) => item?.value),
			fenceOwnerTypes: fenceOwnerTypes.map((item) => item?.value),
			fenceOwnerNames: fenceOwnerNames.map((item) => item?.value),
			cities: cities.map((item) => item?.value),
			alertTypes: alertTypes.map((item) => item?.value),
		});
	};

	const onExportReportClick = async () => {
		await ExportGeoFenceAlertsReport({
			fromDate: startDateHistory,
			toDate: endDateHistory,
			fenceIds: fenceIds.map((item) => item?._id),
			vehicleRnNumbers: vehicleRnNumbers.map((item) => item?.label),
			fenceTypes: fenceTypes.map((item) => item?.value),
			fenceOwnerTypes: fenceOwnerTypes.map((item) => item?.value),
			fenceOwnerNames: fenceOwnerNames.map((item) => item?.value),
			cities: cities.map((item) => item?.value),
			alertTypes: alertTypes.map((item) => item?.value),
		});
	};

	const onTabSelectHandler = async (event) => {
		setActiveTab(event);
		setPage(1);
		if (event === 'non_comm_report') {
			getGeoFanReports({
				fromDate: new Date(new Date().setDate(new Date().getDate() - 1)),
				toDate: new Date(new Date().setTime(new Date().getTime() - 60000)),
				pageSize: ITEMS_PER_PAGE,
			});
			return;
		} else {
			setCities([]);
			setRadioSelected('location_filter');
			setFenceTypes([]);
			setFenceOwnerTypes([]);
			setFenceOwnerNames([]);
			setVehicleRnNumbers([]);
			setFenceIds([]);
			setAlertTypes([]);
			setPage(1);
			resetGeoFanceReport();
			await GetGeoFanceCity();
			await GetSubsRnNums();
		}
	};

	const mappedGeoFanceReportData = mapper.mapGeofenceReportWithMasters(geoFanceReportData, masters)
	const mappedGeoFanceReportHistoryData = mapper.mapGeofenceReportWithMasters(geoFanceReportHistoryData, masters)
	
	return (
		<>
			<div className='col-12'>
			
			</div>
			<div className='col-12 bg-white'>
				<div className='bg-white mb-1 position-relative tabbed-content'>
					
					<div className='col-12'>
						<Container fluid>
							<div className={`col  geo__alert-tab-container ${activeTab === 'non_comm_log' ? 'history-tab-container' : ''}`}>
								<Tabs
									className='alert-nav-link'
									defaultActiveKey='non_comm_report'
									id='uncontrolled-tab-example'
									onSelect={(event) => {
										onTabSelectHandler(event);
									}}
									activeKey={activeTab}
								>
									<Tab eventKey='non_comm_report' title='Recent'>
										<RecentReport
											geoFanceReportData={mappedGeoFanceReportData}
											renderTooltip={renderTooltip}
											renderAlertTimeTooltip={renderAlertTimeTooltip}
											page={page}
											setPage={setPage}
											loading={isLoading}
											onDateChangeHandler={onDateChangeHandler}
											ITEMS_PER_PAGE={ITEMS_PER_PAGE}
											geoFanceReportDataCount={geoFanceReportDataCount}
										/>
									</Tab>
									<Tab eventKey='non_comm_log' title='History'>
										{activeTab === 'non_comm_log' && (
											<HistoryReport
												masters={masters}
												geofences={geofences}
												geoFanceReportData={mappedGeoFanceReportData}
												renderTooltip={renderTooltip}
												renderAlertTimeTooltip={renderAlertTimeTooltip}
												startDate={startDateHistory}
												endDate={endDateHistory}
												cities={cities}
												setCities={setCities}
												fenceIds={fenceIds}
												setFenceIds={setFenceIds}
												vehicleRnNumbers={vehicleRnNumbers}
												setVehicleRnNumbers={setVehicleRnNumbers}
												fenceTypes={fenceTypes}
												setFenceTypes={setFenceTypes}
												fenceOwnerTypes={fenceOwnerTypes}
												setFenceOwnerTypes={setFenceOwnerTypes}
												fenceOwnerNames={fenceOwnerNames}
												setFenceOwnerNames={setFenceOwnerNames}
												alertTypes={alertTypes}
												setAlertTypes={setAlertTypes}
												onDateChangeHandler={onDateChangeHandler}
												onGetReportsClick={onGetReportsClick}
												onExportReportClick={onExportReportClick}
												page={page}
												setPage={setPage}
												geoFanceCityData={geoFanceCityData}
												isLoading={isLoading}
												setRadioSelected={setRadioSelected}
												radioSelected={radioSelected}
												subcribedRnNums={subcribedRnNums}
												ITEMS_PER_PAGE={ITEMS_PER_PAGE}
												geoFanceReportDataCount={geoFanceReportDataCount}
												geoFanceReportHistoryData={mappedGeoFanceReportHistoryData}
												geoFanceReportHistoryDataCount={geoFanceReportHistoryDataCount}
												resetGeoFanceReport={resetGeoFanceReport}
											/>
										)}
									</Tab>
								</Tabs>
							</div>
						</Container>
					</div>
				</div>
			</div>
			<Loader isLoading={isLoading} />
		</>
	);
};

const mapStateToProps = (state) => ({
	geoFanceReportData: state.geoFanceReportReducer.geoFanceReportData,
	geoFanceReportDataCount: state.geoFanceReportReducer.geoFanceReportDataCount,
	geoFanceReportHistoryData: state.geoFanceReportReducer.geoFanceReportHistoryData,
	geoFanceReportHistoryDataCount: state.geoFanceReportReducer.geoFanceReportHistoryDataCount,
	geoFanceOwnersData: state.geoFanceReportReducer.geoFanceOwnersData,
	geoFanceLabelOwnersData: state.geoFanceReportReducer.geoFanceLabelOwnersData,
	geoFanceLabelData: state.geoFanceReportReducer.geoFanceLabelData,
	geoFanceCityData: state.geoFanceReportReducer.geoFanceCityData,
	subcribedRnNums: state.vehicle.subcribedRnNums,
	geoFanceNameData: state.geoFanceReportReducer.geoFanceNameData,
	isLoading: state.loader.isLoading,
});

export default connect(mapStateToProps, {
	getGeoFanReports,
	GetGeoFanceCity,
	GetSubsRnNums,
	getGeoFanceHistoryReports,
	ExportGeoFenceAlertsReport,
	resetGeoFanceReport,
})(GeofanceAlert);
