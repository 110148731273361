import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Table } from "react-bootstrap";
import styles from "./GFConfigureAlertForm.module.css";
import geofenceAction from "../../../../../store/actions/geofence";
import GFAddAlertModel from "./modals/GFAddAlertModel";
import GFAddVehicleModel from "./modals/GFAddVehicleModel";
import GFAssignVehicleModel from "./modals/GFAssignVehicleModel";
import GFAssignVehicleManually from "./modals/GFAssignVehicleManually";
import GFAlertEdit from '../gf-alert-edit/GFAlertEdit'
import UploadFileModel from "./modals/UploadFileModel";
import UploadErrorModel from "./modals/UploadErrorModel";
import InformativeModalLight from "../../../../shared/modals/InformativeModal/InformativeModalLight";

const labelMap = {
  ingress: "Ingress",
  egress: "Egress",
  dwell_time: "Dwell Time",
  return_to_base: "Return to Base",
};

const typeMapping = {
  ingress: "INGRESS",
  egress: "EGRESS",
  dwell_time: "DWELL_TIME",
  return_to_base: "RETURN_TO_BASE_ALERT",
};

const conditionRevMapping = {
  "GREATER_THAN": ">",
  "EQUAL_TO": "=",
  "GREATER_THAN_OR_EQUAL_TO": ">="
}

const GFConfigureAlertForm = ({
  createdFenceId,
  selectedGeofenceData,
  userList,
  groupList,
  mappedOptionsByVehicle,
  refreshGeofences
}) => {

  const { registrationNumber: vehicleList, location: cityOptions, oemModel } = mappedOptionsByVehicle

  const navigate = useNavigate();

  const [refreshCount, setRefreshCount] = useState(0)
  const [editAlertOpen, setEditAlertOpen] = useState(false)
  const [alertDetails, setAlertDetails] = useState(null)
  const [addMoreVehicles, setAddMoreVehicles] = useState(false)
  const [isAssignVehicleOpen, setAssignVehicleOpen] = useState(false);
  const [isManualVehicleOpen, setManualVehicleOpen] = useState(false);
  const [isAddAlertModalOpen, setIsAddAlertModalOpen] = useState(false);
  const [isVehicleModalOpen, setIsVehicleModalOpen] = useState(false);
  const [selectedAssignVehicle, setSelectedAssignVehicle] = useState("");
  const [savedAlerts, setSavedAlerts] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [errorVehicles, setErrorVehicles] = useState({
    isOpen: false,
    validVehicles: [],
    accessRequired: [],
    inValid: [],
    duplicateVehicles: [],
  });
  const [apiResponse, setApiResponse] = useState({
    isOpen: false,
    message: '',
    variant: '',
    data: null,
  })
  const userEmail = JSON.parse(localStorage.getItem("user"))?.email;


  const toggleVehicleModal = () => {
    setIsVehicleModalOpen((prev) => !prev);
  };

  const handleCloseModal = (setter) => () => {
    setter(false);
  };

  const handleSelectVehicleChange = (event) => {
    setSelectedAssignVehicle(event.target.value);
    setAssignVehicleOpen(false);
    setManualVehicleOpen(false);
  };

  const handleNextClick = (value, alert) => {
    if (['manual', 'groups'].includes(selectedAssignVehicle) && value === 'refresh') {
      const updatedAlerts = [...savedAlerts]
      updatedAlerts[alert.index] = alert
      setSavedAlerts([...updatedAlerts])
      setAlertDetails({ ...alertDetails, details: alert })
      setRefreshCount(refreshCount + 1)
    }

    if (selectedAssignVehicle === "groups" || value === 'groups') {
      setAssignVehicleOpen(true);
    } else if (selectedAssignVehicle === "manual" || value === 'manual') {
      setManualVehicleOpen(true);
    }

    if (value) {
      setAddMoreVehicles(true)
    }
  };

  const toggleAddAlert = () => {
    setIsAddAlertModalOpen((prev) => !prev);
  };

  const handleSaveAlerts = (alerts) => {
    setSavedAlerts((prev) => {
      const updatedAlerts = alerts.map((alert, i) => ({
        ...alert,
        index: prev.length + i,
        isSelected: false,
        vehicle: [],
        groups: []
      }));
      return [...prev, ...updatedAlerts];
    });

    setRefreshCount(refreshCount + 1)
    setIsAddAlertModalOpen(false);
  };

  const handleAlertSelect = (index) => {
    setSavedAlerts((prevAlerts) =>
      prevAlerts.map((alert) =>
        alert.index === index
          ? { ...alert, isSelected: !alert.isSelected }
          : alert
      )
    );
    setRefreshCount(refreshCount + 1)
  };

  const showSuccessModal = (message) => {
    setApiResponse({
      ...apiResponse,
      isOpen: true,
      message,
      variant: 'success'
    })
  };

  const formatTo24Hrs = (value) => {
    if (!value) {
      return ''
    }

    const isPM = value.includes('PM')
    value = value.replace(' AM', '').replace(' PM', '')
    let [hrs, mins] = value.split(':')
    if (isPM) {
      if (hrs === 12) {
        hrs = 0
      } else {
        hrs = Number(hrs) + 12
      }
    } else {
      if (hrs < 10) {
        hrs = `0${Number(hrs)}`
      }
    }
    return `${hrs}:${mins}`
  }

  const transformAlertsData = (alertRules) => {
    const alerts = [];
    const userMap = {};

    // Create a mapping for unique users
    for (const key in alertRules) {
      // Check if alerts exist before proceeding
      if (alertRules[key].alerts && alertRules[key].alerts.length > 0) {
        alertRules[key].alerts.forEach((alert) => {
          alert.notification_users.forEach((user) => {
            if (!userMap[user]) {
              userMap[user] = { label: user.split("@")[0], value: user };
            }
          });
        });
      }
    }

    for (const key in alertRules) {
      // Check if alerts exist before proceeding
      if (alertRules[key].alerts && alertRules[key].alerts.length > 0) {
        alertRules[key].alerts.forEach((alert) => {
          let selectedDwellData = null;
          let condition = null;

          if (key === "dwell_time") {
            condition = alert.condition;
            selectedDwellData =

              selectedDwellData = {
                label: conditionRevMapping[condition],
                value: condition,
              };
          }

          alerts.push({
            id: alerts.length + 1,
            configuredBy: alert.configuredBy,
            selectedIngressData: {
              label: key.charAt(0).toUpperCase() + key.slice(1),
              value: key,
            },
            selectedDwellData,
            notification_users: alert.notification_users.map(
              (email) => userMap[email]
            ),
            timeStart: formatTo24Hrs(alert.time?.start),
            timeEnd: formatTo24Hrs(alert.time?.end),
            time:
              key === "dwell_time" || key === "return_to_base"
                ? alert.time / 3600
                : null,
            index: alerts.length,
            isSelected: false,
            vehicle: alert.vehicle,
            groups: alert.groups,
            vehicleCount: alert.vehicle.length,
          });
        });
      }
    }

    return alerts;
  };

  useEffect(() => {
    if (selectedGeofenceData) {
      const alertsData = transformAlertsData(selectedGeofenceData.alert_rules);
      setSavedAlerts(alertsData);
      setRefreshCount(refreshCount + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGeofenceData]);

  const handleSubmit = async () => {
    const fenceRules = {
      ingress: [],
      egress: [],
      dwell_time: [],
      return_to_base: []
    }

    savedAlerts.forEach(alert => {
      let time;
      if (alert.time) {
        time = alert.time * 3600;
      } else {
        time = {
          start: alert.timeStart,
          end: alert.timeEnd,
        };
      }

      fenceRules[alert.selectedIngressData.value].push({
        time,
        condition: alert?.selectedDwellData?.value,
        notification_users: alert.notification_users.map(user => user.value),
        configuredBy: alert?.configuredBy || userEmail,
        ...(alert.groups && { groups: alert.groups }),
        ...(alert.vehicle && { vehicle: alert.vehicle }),
      })
    })

    try {
      const response = await geofenceAction.updateAlerts(createdFenceId, {
        fenceRules: Object.keys(fenceRules).map(fenceRuleKey => ({
          type: typeMapping[fenceRuleKey],
          alerts: fenceRules[fenceRuleKey],
        })),
      });

      if (response?.status === 200) {
        setApiResponse({
          ...apiResponse,
          isOpen: true,
          message: response.data.message || 'Alert configurations saved successfully',
          variant: 'success'
        })
        await refreshGeofences()
        setTimeout(() => {
          navigate('/geo-fence/');
        }, 2010);
      } else {
        setApiResponse({
          ...apiResponse,
          isOpen: true,
          message: response?.data?.error || 'Failed to save alert configuration',
          variant: 'error'
        })
      }
      
    } catch (error) {
      console.error("Error creating geofence:", error);
      setApiResponse({
        ...apiResponse,
        isOpen: true,
        message: 'An error occurred while saving alert configuration',
        variant: 'error'
      })
    }
  };

  const handleGroupVehicleAddClick = (groupData) => {
    const groups = []

    Object.keys(groupData).forEach(dataKey => {
      const data = groupData[dataKey]

      if (data.type === 'group') {
        groups.push({ groupId: data._id, subGroup: [] })
      }

      if (data.type === 'subgroup' && data.parentId) {
        const groupIndex = groups.findIndex(g => g.groupId === data.parentId)
        if (groupIndex !== -1) {
          const subGroup = [...groups[groupIndex].subGroup, data._id]

          groups[groupIndex] = {
            ...groups[groupIndex],
            subGroup: [...new Set(subGroup)]
          }
        } else {
          groups.push({ groupId: data.parentId, subGroup: [data._id] })
        }
      }
    });

    if (alertDetails) {
      const updatedAlerts = [...savedAlerts]
      updatedAlerts[alertDetails.details.index] = {
        ...updatedAlerts[alertDetails.details.index],
        groups,
        isSelected: false,
      }
      setSavedAlerts([...updatedAlerts])
      setAddMoreVehicles(false)

      handleCloseModal(setAssignVehicleOpen)();
      handleCloseModal(setIsVehicleModalOpen)();
    } else {
      setSavedAlerts((prevAlerts) =>
        prevAlerts.map((alert) => {
          if (alert.isSelected) {
            return {
              ...alert,
              groups,
              isSelected: false,
            };
          }
          return alert;
        })
      );
      setRefreshCount(refreshCount + 1)

      handleCloseModal(setAssignVehicleOpen)();
      handleCloseModal(setIsVehicleModalOpen)();
      showSuccessModal("Successfully added the Vehicles for Alert");
    }
  };

  const handleVehicleAddClick = (selectedVehicleNumbers) => {
    if (alertDetails) {
      const updatedAlerts = [...savedAlerts]
      updatedAlerts[alertDetails.details.index] = {
        ...updatedAlerts[alertDetails.details.index],
        vehicle: addMoreVehicles ? [...new Set([...updatedAlerts[alertDetails.details.index].vehicle, ...selectedVehicleNumbers])] : selectedVehicleNumbers,
        isSelected: false,
      }
      setSavedAlerts([...updatedAlerts])
      setAddMoreVehicles(false)

      handleCloseModal(setManualVehicleOpen)();
      handleCloseModal(setIsVehicleModalOpen)();
    } else {
      setSavedAlerts((prevAlerts) =>
        prevAlerts.map((alert) => {
          if (alert.isSelected) {
            return {
              ...alert,
              vehicle: selectedVehicleNumbers,
              isSelected: false,
            };
          }
          return alert;
        })
      );
      setRefreshCount(refreshCount + 1)

      handleCloseModal(setManualVehicleOpen)();
      handleCloseModal(setIsVehicleModalOpen)();
      showSuccessModal("Successfully added the Vehicles for Alert");
    }
  };

  const handleEditSave = (updatedAlert) => {
    const updatedAlerts = [...savedAlerts]
    updatedAlerts[updatedAlert.index] = updatedAlert
    setSavedAlerts([...updatedAlerts])
    setRefreshCount(refreshCount + 1)
    setEditAlertOpen(false)
    setAlertDetails(null)
  }

  const calculateVehicleCount = (alert) => {
    if (alert.vehicle && alert.vehicle.length) {
      return alert.vehicle.length
    }

    if (alert.groups && alert.groups.length && groupList.length) {
      const vehicles = []

      alert.groups.forEach(alertGroup => {
        const groupData = groupList.find(group => group._id === alertGroup.groupId)
        if (groupData) {
          if (!alertGroup.subGroup.length) {
            vehicles.push(...groupData.vehicles)
          } else {
            alertGroup.subGroup.forEach(alertSubGroupId => {
              const subGroupData = groupData.subGroup.find(subGroup => subGroup._id === alertSubGroupId)
              if (subGroupData) {
                vehicles.push(...subGroupData.vehicles)
              }
            })
          }
        }
      })

      return [...new Set(vehicles)].length
    }

    return 0
  }

  const handleUploadFileClick = () => {
    setShowUploadModal(true)
    setManualVehicleOpen(false)
  }

  const handleFileUpload = async (file) => {
    if (!file) {
      console.log("No file selected.");
      return;
    }


    try {
      const response = await geofenceAction.bulkValidate({ selectedFile: file });
      let validVehicles = response?.data?.data?.valid || [];
      const accessRequired = response?.data?.data?.accessRequired || [];
      const inValid = response?.data?.data?.inValid || [];
      let duplicateVehicles = [];

      if (validVehicles.length > 0) {
        handleVehicleAddClick(validVehicles)
        if (alertDetails?.details?.vehicle) {
          validVehicles = validVehicles.filter(v => {
            if (alertDetails.details.vehicle.includes(v)) {
              duplicateVehicles.push(v)
              return false
            }
            return true
          })
        }
      }

      const hasErrors = accessRequired.length > 0 || inValid.length > 0;

      setErrorVehicles({
        validVehicles,
        accessRequired,
        inValid,
        duplicateVehicles,
        isOpen: hasErrors,
      });

      setShowUploadModal(false);
    } catch (error) {
      setShowUploadModal(false);
    }
  };

  const handleInfoModalClose = () => {
    setApiResponse({
      ...apiResponse,
      isOpen: false,
      message: '',
      variant: '',
      data: null
    })
  }

  return (
    <div className={styles.addAlertContainer}>
      <div className={styles.AddAlertButtonBox}>
        <button className={styles.AddAlertButton} onClick={toggleAddAlert}>
          <img
            className={styles.AddAlertIcon}
            src="/images/geofenceModuleIcon/AddAlertIcon.svg"
            alt="toggle icon"
          />
          Add Alert
        </button>
      </div>
      <div className={styles.alertDetailCard}>
        <div>
          <Table borderless className="mt-4" id="alertDetailsTable">
            <thead className={styles.alertDetailsTable}>
              <tr>
                <th className="text-nowrap">
                  <p className={styles.alertTableHeader}>Alert</p>
                </th>
                <th className="text-nowrap">
                  <p className={styles.alertTableHeader}>Vehicle Count</p>
                </th>
                <th className="text-nowrap">
                  <p className={styles.alertTableHeader}>Action</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {savedAlerts.map((alert, index) => {
                const vehicleCount = calculateVehicleCount(alert)
                const isEditAllowed = !alert.configuredBy || alert.configuredBy === userEmail
                return (
                  <tr key={`alert_${index}`} className={styles.alertDataList}>
                    <td className={styles.selectColumn}>
                      <p>
                        {(() => {

                          const alertLabel =
                            labelMap[alert.selectedIngressData?.value] ||
                            alert.selectedIngressData?.label;

                          if (alert.selectedIngressData?.value === "dwell_time") {
                            return `${alertLabel}: ${alert.selectedDwellData?.label} ${alert.time} Hrs`;
                          } else if (
                            alert.selectedIngressData?.value === "return_to_base"
                          ) {
                            return `${alertLabel}: ${alert.time} Hrs`;
                          } else {
                            return `${alertLabel} Alert: ${alert.timeStart} to ${alert.timeEnd}`;
                          }
                        })()}
                      </p>
                    </td>
                    <td
                      className={
                        !vehicleCount
                          ? styles.alertCountstatusZero
                          : styles.alertCountstatus
                      }
                    >
                      {vehicleCount}
                    </td>
                    <td>
                      <button
                        className={styles.alertEditIcon}
                        onClick={() => {
                          setAlertDetails({ alertType: alert.selectedIngressData?.value, details: alert })
                          setEditAlertOpen(true)
                        }}
                      >
                        <img
                          src="/images/geofenceModuleIcon/editIcon.svg"
                          alt="edit icon"
                        />
                      </button>
                      <button
                        className={styles.alertEditIcon}
                        disabled={!isEditAllowed}
                        onClick={() => {
                          const newAlerts = [...savedAlerts].filter(
                            (_, i) => i !== index
                          );
                          setSavedAlerts([...newAlerts]);
                          setRefreshCount(refreshCount + 1)
                        }}
                      >
                        <img
                          src="/images/geofenceModuleIcon/deleteIcon.svg"
                          alt="delete icon"
                        />
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </div>

      <div className={`${styles.saveBtnBox} ${styles.submitButtonStyles}`}>
        <button
          className={styles.saveBtnLocation}
          style={{ backgroundColor: "#22046B" }}
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>

      {isAddAlertModalOpen && (
        <GFAddAlertModel
          toggleAddAlert={toggleAddAlert}
          userList={userList}
          handleSaveAlerts={handleSaveAlerts}
        />
      )}

      {isVehicleModalOpen && (
        <GFAddVehicleModel
          isOpen={isVehicleModalOpen}
          onClose={toggleVehicleModal}
          selectedAlerts={savedAlerts}
          handleSelectVehicleChange={handleSelectVehicleChange}
          handleNextClick={handleNextClick}
          selectedAssignVehicle={selectedAssignVehicle}
          handleAlertSelect={handleAlertSelect}
        />
      )}

      {isAssignVehicleOpen && (
        <GFAssignVehicleModel
          onClose={handleCloseModal(setAssignVehicleOpen)}
          vehicleList={vehicleList}
          handleNextClick={handleGroupVehicleAddClick}
          groupList={groupList}
          alertDetails={alertDetails?.details}
          refreshCount={refreshCount}
        />
      )}

      {isManualVehicleOpen && (
        <GFAssignVehicleManually
          vehicleList={vehicleList}
          cityOptions={cityOptions}
          oemModel={oemModel}
          onClose={handleCloseModal(setManualVehicleOpen)}
          handleNextClick={handleVehicleAddClick}
          alertDetails={alertDetails?.details}
          onUploadFileClick={handleUploadFileClick}
        />
      )}

      {showUploadModal && (
        <UploadFileModel
          showUploadModal={showUploadModal}
          setShowUploadModal={setShowUploadModal}
          handleFileUpload={handleFileUpload}
        />
      )}

      {errorVehicles.isOpen && (
        <UploadErrorModel
          showUploadModal={errorVehicles.isOpen}
          setShowUploadModal={setErrorVehicles}
          errorVehicles={errorVehicles}
        />
      )}

      {editAlertOpen && (
        <GFAlertEdit
          mappedOptionsByVehicle={mappedOptionsByVehicle}
          userEmail={userEmail}
          userList={userList}
          groupList={groupList}
          isOpen={editAlertOpen}
          onClose={() => {
            setEditAlertOpen(false)
            setAlertDetails(null)
          }}
          alertDetails={alertDetails?.details}
          alertType={alertDetails?.alertType}
          onSave={handleEditSave}

          selectedAssignVehicle={selectedAssignVehicle}
          handleSelectVehicleChange={handleSelectVehicleChange}
          handleNextClick={(value, alertDetails) => {
            handleNextClick(value, alertDetails)
            setEditAlertOpen(false)
          }}
        />
      )}

      <InformativeModalLight
        isOpen={apiResponse.isOpen}
        onClose={handleInfoModalClose}
        message={apiResponse.message}
        variant={apiResponse.variant}
      />
    </div>
  );
};

export default GFConfigureAlertForm;
