import cx from 'classnames'

import styles from './GFDetailsPanel.module.css'
import { useEffect, useRef } from 'react';

const amenitiesIconMap = {
    CHARGING: "/images/geofenceModuleIcon/chargingIcon.svg",
    PARKING: "/images/geofenceModuleIcon/parkingIcon.svg",
    SERVICING: "/images/geofenceModuleIcon/servicingIcon.svg",
    STORAGE: "/images/geofenceModuleIcon/storageIcon.svg",
    HIRING_DESK: "/images/geofenceModuleIcon/hiringDeskIcon.svg",
    LOADING_UNLOADING: "/images/geofenceModuleIcon/loadingUnload.svg",
};

const decimalToDMS = (decimal) => {
    const degrees = Math.floor(decimal)
    const minutesFloat = (decimal - degrees) * 60
    const minutes = Math.floor(minutesFloat)
    const seconds = (minutesFloat - minutes) * 60

    return `${degrees}° ${minutes}' ${seconds.toFixed(2)}"`
}

const GFDetailsPanel = (props) => {
    const { fenceDetails, fenceTypeIconMap, onCloseClick, onEditFenceClick, onDeleteFenceClick } = props
    const panelRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (panelRef.current && !panelRef.current.contains(event.target)) {
                onCloseClick()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        };
    }, [onCloseClick])

    if (!fenceDetails) {
        return <></>
    }

    const { fence_identifiers, fence_geometry } = fenceDetails
    const lat = fenceDetails?.center_coordinates[0]
    const lng = fenceDetails?.center_coordinates[1]

    return (
        <div className={styles.panelContainer} ref={panelRef}>
            <div className={cx(styles.panelSection, styles.panelSectionHeader)}>
                <div className={styles.panelSectionRow} style={{ justifyContent: 'space-between' }}>
                    <span className={styles.fenceNameTitle}>{fenceDetails.fence_name}</span>

                    <button onClick={onCloseClick}>
                        <img src="/images/geofenceModuleIcon/closeBtn.svg" alt='close fence details panel icon' />
                    </button>
                </div>
                <div className={styles.panelSectionRow} style={{ justifyContent: 'flex-start' }}>
                    <span title={fenceDetails.createdBy} className={styles.fenceCreatedBy}>{fenceDetails.createdBy}</span>
                </div>
            </div>

            <div className={cx(styles.panelSection, styles.typePanelSection)}>
                <div className={styles.fenceTypeWrapper}>
                    <img
                        className={styles.fenceTypeIcon}
                        src={fenceTypeIconMap[fence_identifiers.fence_type] || '/images/geofenceModuleIcon/chargingDepot.svg'}
                        alt='fence type icon'
                    />

                    <p className={styles.fenceTypeLabel}>{fence_identifiers.fence_type_label || 'Charging Depot'}</p>
                </div>
                
                <table className={styles.fenceInfoTable}>
                    <tr>
                        <td>Fence Owner Type</td>
                        <td>:</td>
                        <td>{fence_identifiers.owner_type_label || '-'}</td>
                    </tr>
                    <tr>
                        <td>Fence Owner Name</td>
                        <td>:</td>
                        <td>{fence_identifiers.owner_name_label || '-'}</td>
                    </tr>
                </table>
            </div>

            <div className={cx(styles.panelSection, styles.infoPanelSection)}>
                <table className={styles.fenceInfoTable}>
                    <tr>
                        <td>Address</td>
                        <td>:</td>
                        <td>{fence_identifiers.address}</td>
                    </tr>
                    <tr>
                        <td>Area</td>
                        <td>:</td>
                        <td>{Math.round(fence_geometry?.area?.value)} {fence_geometry?.area?.unit}</td>
                    </tr>
                    <tr>
                        <td>City</td>
                        <td>:</td>
                        <td>{fence_identifiers.city}</td>
                    </tr>
                    <tr>
                        <td>Coordinates</td>
                        <td>:</td>
                        <td>{decimalToDMS(Math.abs(lat)) + (lat >= 0 ? ' N' : ' S')}, {decimalToDMS(Math.abs(lng)) + (lng >= 0 ? ' E' : ' W')}</td>
                    </tr>
                </table>
            </div>

            <div className={cx(styles.panelSection, styles.amenitiesPanelSection)}>
                <span className='mb-2'>Amenities</span>

                <div className={cx(styles.amenitiesWrapper, 'mb-3')}>
                    {fenceDetails.amenities.length ? (
                        fenceDetails.amenities.map((amenity, amenityI) => (
                            <div key={`amenity_${amenityI}`} className={styles.amenityBox}>
                                {amenitiesIconMap[amenity] && (
                                    <img
                                        src={amenitiesIconMap[amenity]}
                                        alt={`${amenity} icon`}
                                        className={styles.amenityIcon}
                                    />
                                )}
                                <span>{fenceDetails.amenities_label[amenityI]}</span>
                            </div>
                        ))
                    ) : (
                        <p>No amenities available</p>
                    )}
                </div>
                
                <div className={styles.panelSectionRow}>
                    <button className={styles.panelButton} onClick={onEditFenceClick}>Edit</button>
                    <button disabled={!fenceDetails.canUpdate} className={styles.panelButton} onClick={onDeleteFenceClick}>Delete</button>
                </div>
            </div>
        </div>
    )
}

export default GFDetailsPanel;