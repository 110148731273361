import React from "react";
import MultiSelect from "../../../../helpers/hoc/multi-select/MultiSelect";
import styles from './GFEditAlertDetails.module.css'

const ALERT_OPTIONS = [
  { label: "Ingress", value: "ingress" },
  { label: "Egress", value: "egress" },
  { label: "Dwell Time", value: "dwell_time" },
  { label: "Return to Base Alert", value: "return_to_base" },
]

const GFEditAlertDetails = (props) => {
  const { alertDetails, setAlertDetails, alertType, userList, handleSaveClick, alertError, setAlertError, isEditAllowed, userEmail } = props
  if (!alertDetails) { return <></> }

  const alertTypeValue = ALERT_OPTIONS.find(a => a.value === alertType)

  const dwellOptions = [
    { label: ">", value: "GREATER_THAN" },
    { label: "=", value: "EQUAL_TO" },
    { label: ">=", value: "GREATER_THAN_OR_EQUAL_TO" },
  ]

  const handleUserSelectChange = (updatedOptions) => {
    setAlertDetails((prevState) => ({
      ...prevState,
      notification_users: updatedOptions
    }))
  };

  const handleTimeChange = ({ name, value }) => {
    let newAlertError = { ...alertError }
    let newAlertDetails = { ...alertDetails }

    newAlertError = {
      time: ''
    }

    if (name === 'time' && value && (!/^\d+$/.test(value) || value < 1 || value > 24)) {
      newAlertError = {
        ...newAlertError,
        time: 'Only numeric values from 1 to 24 are allowed'
      }
    }

    newAlertDetails[name] = value

    if (['timeStart', 'timeEnd'].includes(name) && newAlertDetails.timeStart && newAlertDetails.timeEnd && newAlertDetails.timeStart === newAlertDetails.timeEnd) {
      newAlertError = {
        ...newAlertError,
        time: 'Start and end time can not be same'
      }
    }

    if (!newAlertError.time) {
      setAlertDetails((prevState) => ({
        ...prevState,
        [name]: value
      }))
    }

    setAlertError({ ...newAlertError })
  };

  const handleSelectChange = (value, type) => {
    setAlertDetails((prevState) => ({ ...prevState, [type]: value }));
  };

  const renderTimeInputs = ({ timeEnd, timeStart }) => (
    <div className={styles.alertTimeCol}>
      <label className={styles.fenceDetailsLabel}>Set Time:</label>
      <input
        className={styles.alertTimeInput}
        type="time"
        value={timeStart}
        disabled={!isEditAllowed}
        onChange={(e) => handleTimeChange({ value: e.target.value, name: "timeStart" })}
      />
      <span>to</span>
      <input
        className={styles.alertTimeInput}
        type="time"
        value={timeEnd}
        disabled={!isEditAllowed}
        onChange={(e) => handleTimeChange({ value: e.target.value, name: "timeEnd" })}
      />
      {alertError.time && <span className={styles.alertTimeError}>{alertError.time}</span>}
    </div>
  );

  const renderDwellTimeInputs = ({ selectedDwellData, time }) => (
    <div className={styles.alertTimeCol}>
      <label className={styles.fenceDetailsLabel}>Set Time:</label>
      {isEditAllowed ? (
        <MultiSelect
          isMultiSelect={false}
          placeholder="Select"
          options={dwellOptions}
          selectedOptions={[selectedDwellData]}
          onChange={(value) =>
            handleSelectChange(value[0], "selectedDwellData")
          }
          customBackgroundColor="#E7ECF7"
          webkitScrollbarWidth="5px"
          singleValueColor="#1F2A5D"
          valueContainerJustifyContent="start"
          valueContainerWidthInPx="52px"
          valueContainerMaxWidthInPx="52px"
          optionPosition="ps-2 justify-content-start"
          placeholderAlignment="flex-start"
          indicatorSeparatorDisplay="none"
          maxMenuHeight={165}
          isClearable={false}
          isSearchable={false}
        />
      ) : (
        <input
          type="text"
          value={selectedDwellData.label}
          className={styles.alertTypeInput}
          style={{ maxWidth: '52px' }}
          readOnly
        />
      )}
      
      <div className={styles.timeInputContainer}>
        <input
          className={styles.alertTimeInput}
          value={time}
          disabled={!isEditAllowed}
          style={{ width: '46px' }}
          onChange={(e) => handleTimeChange({ value: e.target.value, name: "time" })}
        />
        <span className={styles.hrsLabel}>Hrs</span>
      </div>

      {alertError.time && <span className={styles.alertTimeError}>{alertError.time}</span>}
    </div>
  );

  const renderReturnToBaseInputs = ({ time }) => {
    return (
      <div className={`${styles.alertTimeCol}`}>
        <label
          className={styles.fenceDetailsLabel}
        >
          Set Time:
        </label>

        <div className={styles.timeInputContainer}>
          <input
            className={styles.alertTimeInput}
            value={time}
            disabled={!isEditAllowed}
            style={{ width: '46px' }}
            onChange={(e) => handleTimeChange({ value: e.target.value, name: "time" })}
          />
          <span className={styles.hrsLabel}>Hrs</span>
        </div>
      </div>
    );
  };

  const renderUserSelection = ({ notification_users }) => (
    <div className={styles.alertUserCol}>
      <label className={styles.fenceDetailsLabel}>Select User</label>

      <div style={{ width: "220px" }}>
        <MultiSelect
          isClearable={isEditAllowed}
          disableSelectAll={!isEditAllowed}
          options={userList.map(d => ({ ...d, isDisabled: !isEditAllowed && d.value !== userEmail }))}
          selectedOptions={notification_users}
          placeholder="Select"
          customBackgroundColor="#E9EDF8"
          isMultiSelect
          setSelectedOptions={(updatedOptions) =>
            handleUserSelectChange(updatedOptions)
          }
          webkitScrollbarWidth="5px"
          webkitScrollbarHeight="5px"
          minHeight="40px"
          placeholderAlignment="flex-start"
          indicatorSeparatorDisplay="none"
          placeholderColor="#C6C6C6"
          valueContainerColor="#797694"
          maxMenuHeight={90}
        />
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.alertRow}>
        <div className={styles.alertTypeCol}>
          <label>Alert Type:</label>
          <input
            type="text"
            value={alertTypeValue.label}
            className={styles.alertTypeInput}
            readOnly
          />
        </div>

        {(alertType === "ingress" || alertType === "egress") && renderTimeInputs(alertDetails)}

        {alertType === "dwell_time" && renderDwellTimeInputs(alertDetails)}

        {alertType === "return_to_base" && renderReturnToBaseInputs(alertDetails)}

        {renderUserSelection({ notification_users: alertDetails.notification_users })}
      </div>
      <div className={styles.modalFooter}>
        <button className={styles.modalSaveButton} onClick={handleSaveClick}>Save</button>
      </div>
    </>
  )
}

export default GFEditAlertDetails;