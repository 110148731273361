import React, { useState, useMemo, useEffect } from "react";
import styles from "./AssignVehicleModel.module.css";
import { FaSearch } from "react-icons/fa";
import Pagination from "../../../../../helpers/hoc/paginator/Pagination";

const GFAssignVehicleModel = ({ onClose, handleNextClick, vehicleList, groupList, alertDetails, refreshCount }) => {
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedVehiclesIds, setSelectedVehicles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  
  const [searchQuery, setSearchQuery] = useState("");
  const ITEMS_PER_PAGE = 8;

  useEffect(() => {
    if (alertDetails.groups && alertDetails.groups.length) {
      const newSelectedItems = {}
      
      alertDetails.groups.forEach(group => {
        const groupData = groupList.find(gl => gl._id === group.groupId)
        if (group.subGroup.length) {
            group.subGroup.forEach(subGroup => {
                const subGroupData = groupData.subGroup.find(sgl => sgl._id === subGroup)
                newSelectedItems[subGroupData._id] = {
                  type: 'group',
                  ...subGroupData,
                }
            })
        } else {
          newSelectedItems[groupData._id] = {
            type: 'group',
            ...groupData,
          }  
        }
      })
      
      setSelectedVehicles(mergeGroupVehicles(newSelectedItems))
      setSelectedItems(newSelectedItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertDetails, refreshCount])
  

  const mergeGroupVehicles = (groupsData) => {
    const totalVehicles = []
    Object.keys(groupsData).forEach(group => {
      totalVehicles.push(...groupsData[group]?.vehicles)
    })
    return [...new Set(totalVehicles)]
  }

  const handleCheckboxChange = (id, data, type, groupData) => {
    const newSelectedItems = { ...selectedItems };
    
    if (newSelectedItems[id]) {
      delete newSelectedItems[id];
    } else {
      newSelectedItems[id] = {
        ...data,
        type,
      };
    }

    if (type === 'group' && data.subGroup.length) {
      // when a group is selected remove selected subgroups
      data.subGroup.forEach(d => {
        delete newSelectedItems[d._id]
      })
    }

    if (type === 'subgroup' && groupData) {
      // remove main group id if a subgroup is selected
      delete newSelectedItems[groupData._id]

    }

    setSelectedVehicles(mergeGroupVehicles(newSelectedItems));
    setSelectedItems(newSelectedItems);
    handlePageChange(1)
  };

  const toggleAccordion = (groupId) => {
    setExpandedGroup((prevGroup) => (prevGroup === groupId ? null : groupId));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filteredVehicleList = useMemo(() => {
    return vehicleList.filter((vehicle) => {
      const matchesId = selectedVehiclesIds.includes(
        vehicle.registration_number
      )
      const matchesSearchQuery = vehicle.label
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      return matchesId && matchesSearchQuery;
    });
  }, [vehicleList, selectedVehiclesIds, searchQuery]);

  const totalPages = Math.ceil(filteredVehicleList.length / ITEMS_PER_PAGE);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return filteredVehicleList.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  }, [filteredVehicleList, currentPage]);

  const isNextButtonEnabled = Object.keys(selectedItems).length > 0;

  const filteredGroupList = !searchQuery.length ? groupList : groupList.filter(group => {
    return `${group.name}`.toLowerCase().includes(searchQuery.toLowerCase())
  })

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.closeBtn} onClick={onClose}>
          <img src="/images/geofenceModuleIcon/closeBtn.svg" alt="close" />
        </div>

        <div className={styles.modalBody}>
          <div className={styles.groupSelection}>
            <div className={styles.searchContainerGeofence}>
              <FaSearch className={styles.vobSearchIcon} />
              <input
                type="text"
                className={styles.vobSearchInput}
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                  handlePageChange(1)
                }}
              />
            </div>
            <h3>All Groups</h3>
            {/* <h4 className={styles.groupHeaderTitle}>Group Name</h4> */}
            <ul style={{ paddingLeft: "10px", overflow: "auto", maxHeight: "calc(545px - 145px)" }}>
              {filteredGroupList?.map((group, index) => (
                <li key={index}>
                  <div
                    className={styles.groupHeader}
                  >
                    <div className={styles.groupCheckbox}>
                      <input
                        type="checkbox"
                        checked={!!selectedItems[group._id]}
                        disabled={group.subGroup.some(g => selectedItems[g._id])}
                        onChange={() => handleCheckboxChange(group._id, group, 'group')}
                      />
                      <span className={styles.groupHeaderTitle}>
                        {group.name}
                      </span>
                    </div>
                    {group?.subGroup?.length > 0 && (
                      <img
                        src={
                          expandedGroup === group._id
                            ? "/images/geofenceModuleIcon/upArrow.svg"
                            : "/images/geofenceModuleIcon/downArrow.svg"
                        }
                        alt={
                          expandedGroup === group._id ? "Collapse" : "Expand"
                        }
                        className={styles.accordionIcon}
                        onClick={() => toggleAccordion(group._id)}
                      />
                    )}
                  </div>
                  {expandedGroup === group._id && group.subGroup.length > 0 && (
                    <ul>
                      {group.subGroup.map((subGroup, subIndex) => (
                        <li key={subIndex} className={styles.subGroupItem}>
                          <input
                            style={{ marginRight: "7px" }}
                            type="checkbox"
                            disabled={!!selectedItems[group._id]}
                            checked={!!selectedItems[group._id] || !!selectedItems[subGroup._id]}
                            onChange={() =>
                              handleCheckboxChange(subGroup._id, subGroup, 'subgroup', group)
                            }
                          />
                          <span className={styles.groupHeaderTitle}>
                            {subGroup.name}
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div className={styles.groupData}>
            <h3>All Groups</h3>
            <table>
              <thead className={styles.groupDataTable}>
                <tr>
                  <th>S.NO</th>
                  <th>VEHICLE</th>
                  <th>OEM & MODEL</th>
                  <th>CITY</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((data, index) => (
                  <tr key={index} className={styles.fetchGroupData}>
                    <td>{(currentPage - 1) * ITEMS_PER_PAGE + index + 1}</td>
                    <td>{data.label}</td>
                    <td>{data.oem}</td>
                    <td>{data.location}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {paginatedData.length > 0 && (
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                setPage={handlePageChange}
                data-testid="unresolved-pagination"
              />
            )}
          </div>

          <div className={styles.createdGroups}>
            <h3>Created Groups</h3>
            <ul style={{ padding: "10px" }}>
              {Object.keys(selectedItems).map((groupId, index) => {
                const group = selectedItems[groupId]
                return (
                  group && (
                    <li className={styles.createdGroupsList} key={index}>
                      {group.name} ({group.count})
                      <img style={{cursor:'pointer'}}
                        src="/images/geofenceModuleIcon/cancelButton.svg"
                        alt="close"
                        onClick={() => handleCheckboxChange(groupId, group)}
                      />
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className={styles.assignVehicleSaveButton}
            style={{
              backgroundColor: isNextButtonEnabled ? "#22046B" : "#9E9E9E",
              cursor: isNextButtonEnabled ? "pointer" : "not-allowed",
            }}
            disabled={!isNextButtonEnabled}
            onClick={() => handleNextClick(selectedItems)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default GFAssignVehicleModel;
