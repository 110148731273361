import React, { useState, Fragment, useEffect, useRef, useCallback, useMemo } from 'react'
import Select, { components } from 'react-select'
import { IoIosCheckbox, IoIosSquareOutline } from 'react-icons/io'
import './MultiSelect.css'

export default function MultiSelect({
  selectedOptions = [],
  setSelectedOptions,
  options = [],
  setNoDataWarning = false,
  placeholder = 'Vehicle Registration No.',
  placeholderColor = "hsl(0, 0%, 50%)",
  placeholderOnError = 'Select registration no.',
  valueContainerWidthInPx = '300px',
  valueContainerMaxWidthInPx = '300px',
  valueContainerColor="#1F2A5D",
  menuListPaddingX = 'px-5',
  menuListPaddingY = 'py-3',
  okButtonPaddingEnd = 'pe-4',
  bottomScrollbarPadding = 'py-2',
  fontWeight = '500',
  webkitScrollbarWidth = '10px',
  webkitScrollbarHeight = '10px',
  indicatorSeparatorDisplay = 'block',
  isMultiSelect = false,
  optionPosition = 'justify-content-center',
  maxMenuHeight = 276,
  customBackgroundColor = '#E7ECF7',
  maxOptionSelectionLimit = options.length,
  maxSelectionExceedErrorMsg = "Max option selection limit exceeded",
  height = '38px',
  minHeight = '38px',
  placeholderAlignment = 'center',
  onChange = () => {},
  menuWidth = '100%',
  ...props
}) {
  const baseTestId = props['data-testid'] || 'multiselect'
  const myDivRef = useRef()
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false)

  function handleSelect(data) {
    onChange(!isMultiSelect ? [data] : data)
    if (setSelectedOptions) {
      setSelectedOptions(!isMultiSelect ? [data] : data)
    }
  }

  function selectAllOpts() {
    if (Array.isArray(selectedOptions) && options.length !== selectedOptions?.length) {
      if(setSelectedOptions) {
        setSelectedOptions(options)
      }
    }
  }

  function unSelectAllOpts() {
    if(setSelectedOptions) {
      setSelectedOptions([])
    }
  }

  function toggleSelectMenuOpenClose(bool) {
    setIsSelectMenuOpen(bool)
  }

  const isMaxSelectionLimitExceeded = useMemo(()=>{
    return Array.isArray(selectedOptions) && selectedOptions.length >= maxOptionSelectionLimit && maxOptionSelectionLimit !== options.length
  },[selectedOptions, maxOptionSelectionLimit, options])

  const isAllOptionsSelected = useMemo(()=>{
    return Array.isArray(selectedOptions) && selectedOptions.length === options.length
  },[selectedOptions, options])

  const sortedOptions = useMemo(()=>{
    if (Array.isArray(selectedOptions) && selectedOptions.length && options.length !== selectedOptions.length) { 
      const optionValues = selectedOptions.map(val => val?.value)
      const newOptions = []
      options.forEach((element) => {
        if (optionValues.includes(element?.value)) {
          newOptions.unshift(element)
        } else {
          newOptions.push(element)
        }
      });
      return newOptions
    } else {
       return options
     }
// eslint-disable-next-line
  },[isSelectMenuOpen])

  const MenuList = useCallback((props) => {
    return (
      <Fragment>
        {isMultiSelect && options.length === maxOptionSelectionLimit && (
          <div
            className={`d-flex flex-row align-items-center justify-content-between ${menuListPaddingX}  ${menuListPaddingY}`}
          >
            <button
              onClick={() => {
                selectAllOpts()
              }}
              className="multi-select-menu-buttons"
              data-testid={`${baseTestId}-option-select-all`}
            >
              Select all
            </button>

            <button
              onClick={() => {
                unSelectAllOpts()
              }}
              className="multi-select-menu-buttons"
              data-testid={`${baseTestId}-option-clear-all`}
            >
              Clear all
            </button>
          </div>
        )}
        <components.MenuList {...props}>
          {props.children}
        </components.MenuList>

        { isMaxSelectionLimitExceeded && 
          <span className='menu-list-max-option-limit' >
            {maxSelectionExceedErrorMsg}
          </span> }

      </Fragment>
    )
    // eslint-disable-next-line
  },[isMaxSelectionLimitExceeded, isAllOptionsSelected, isMultiSelect])

  const Option = useCallback((props) => {
    return (
      <div className="select-option-div">
        <components.Option { ...{...props, innerProps: {...props.innerProps, 'data-testid': `${baseTestId}-option-${props.label}` }}}>
          {isMultiSelect ? (
            <div
              className={`d-flex flex-row align-items-center ${optionPosition} gap-2 w-100`}
            >
              <div
                className={`d-flex flex-row align-items-center ps-2  justify-content-start gap-2 ${
                  optionPosition === 'justify-content-center' ? 'w-50' : ''
                }`}
              >
                {!props.isSelected && (
                  <div style={{ cursor: 'pointer' }}>
                    <IoIosSquareOutline color="#707070" size={20} />
                  </div>
                )}
                {props.isSelected && (
                  <div>
                    <IoIosCheckbox color="#22046B" size={20} />
                  </div>
                )}
                <label
                  style={{
                    fontWeight: fontWeight,
                  }}
                >
                  {props.label}
                </label>
              </div>
            </div>
          ) : (
            <div
              className={`d-flex flex-row align-items-center ${optionPosition} gap-2 w-100`}
            >
              <div
                className={`d-flex flex-row align-items-center ps-2  justify-content-start gap-2 ${
                  optionPosition === 'justify-content-center' ? 'w-50' : ''
                }`}
              >
                <label>{props.label}</label>
              </div>
            </div>
          )}
        </components.Option>
      </div>
    )
    // eslint-disable-next-line
  }, [isMultiSelect])

  const Control = useCallback(({ children, ...props }) => {
    return (
      <components.Control { ...{...props, innerProps: { ...props.innerProps, 'data-testid': `${baseTestId}-control` }} }>
        {children}
      </components.Control>
    )    
  }, [baseTestId])

  // Function to execute when clicking outside the div
  function handleClickOutside(event) {
    if (myDivRef.current && !myDivRef.current.contains(event.target)) {
      toggleSelectMenuOpenClose(false)
      return
    }
  }

  function handleClick(event) {
    if (isMultiSelect) {
      event.stopPropagation()
    }
    toggleSelectMenuOpenClose(true)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
    // eslint-disable-next-line
  }, [])



  const customFilter = (option, searchText) => {
    if (
      option?.data?.label.toLowerCase().includes(searchText?.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div ref={myDivRef} onClick={handleClick}>
      <Select
        filterOption={customFilter}
        options={sortedOptions}
        id="react-mselect"
        closeMenuOnSelect={false}
        placeholder={setNoDataWarning ? placeholderOnError : placeholder}
        value={selectedOptions}
        maxMenuHeight={maxMenuHeight}
        onChange={handleSelect}
        menuIsOpen={isSelectMenuOpen}
        styles={{
          valueContainer: (styles, state) => {
            return {
              ...styles,
              height,
              textOverflow: 'ellipsis',
              width: valueContainerWidthInPx,
              maxWidth: valueContainerMaxWidthInPx,
              backgroundColor: customBackgroundColor,
              fontSize: '14px',
              whiteSpace: 'nowrap',
              fontWeight: fontWeight,
              overflow: 'hidden',
              justifyContent: placeholderAlignment,
              display: 'flex',
              color: '#1F2A5D',
              active: {
                backgroundColor: customBackgroundColor,
              },
            }
          },
          control: (styles) => {
            return {
              ...styles,
              backgroundColor: customBackgroundColor,
              borderColor: customBackgroundColor,
              boxShadow: 'none',
              height: height,
              minHeight: minHeight,

              ':hover': {
                ...styles[':hover'],
                borderColor: customBackgroundColor,
              },
            }
          },
          placeholder: (styles, state) => {
            return {
              ...styles,
              color: `${
                state.children === placeholder ? placeholderColor : '#10204B'
              }`,
            }
          },
          menu: (styles) => {
            return {
              ...styles,
              textWrap: 'nowrap',
              width: menuWidth,
              zIndex: 8,
            }
          },
          indicatorSeparator: (styles) => {
            return {
              ...styles,
              display: indicatorSeparatorDisplay,
            }
          },
          menuList: (styles) => {
            return {
              ...styles,
              '::-webkit-scrollbar': {
                width: webkitScrollbarWidth,
                height: webkitScrollbarHeight,
              },
            }
          },
          option: (styles, { isDisabled, isSelected }) => {
            return {
              ...styles,
              backgroundColor:
                isDisabled && !isSelected ? '#edf0ef' : '#FFFFFF',
              color: '#1f2a5d',
              textAlign: 'center',
              fontFamily: 'inherit',
              fontSize: '14px',
              cursor: isDisabled ? 'not-allowed' : 'default',
              ':active': {
                ...styles[':active'],
                backgroundColor: '#FFFFFF',
              },
            }
          },
          singleValue: (styles, state) => {
            return {
              ...styles,
              color: '#10204B',
            }
          },
        }}
        components={{
          MultiValueContainer: ({ selectProps, data }) => {
            const label =
              selectProps.value.length > 1 ? `${data.label}, ` : data.label
            const val = `${selectProps.inputValue ? '' : label}`
            return val
          },
          Option: Option,
          MenuList: MenuList,
          Control: Control,
        }}
        isOptionDisabled={(option, selectValue) => {
          return (
            Array.isArray(selectedOptions) && selectedOptions.length >= maxOptionSelectionLimit &&
            !selectValue.map((val) => val.label).includes(option.label)
          )
        }}
        isMulti={isMultiSelect}
        isSearchable={true}
        hideSelectedOptions={false}
        isClearable={true}
        allowSelectAll={
          isMultiSelect && selectedOptions.length === maxOptionSelectionLimit
        }
        className={setNoDataWarning ? 'box-shadow-danger' : ''}
        onBlur={() => toggleSelectMenuOpenClose(false)}
      />
    </div>
  )
}
