import API from "../../service/api";
import API_BOOK from "../../service/endpoints";
import ActionTypes from "../actionTypes";

const geofenceAction = {}

/**
 *  @desc         	Get Geofence Masters
 *  @author      	Amir Shaikh
 *  @api          	/geofence/masters
 *  @method       	GET
 *  @createdDate    11-OCT-2024
 *  @modifiedDate 	11-OCT-2024
 **/
geofenceAction.getMasters = async () => {
    try {
        const response = await API({
            url: API_BOOK.GEOFENCE.GET_MASTERS.url,
            method: API_BOOK.GEOFENCE.GET_MASTERS.method
        })
        return response
    } catch (error) {
        console.log('error', error)
    }
}

geofenceAction.setMasters = (masters) => ({
    type: ActionTypes.GEOFENCE.MASTERS_SUCCESS,
    payload: masters
})

/**
 *  @desc         	Get geofences list
 *  @author      	Amir Shaikh
 *  @api          	/geofence/
 *  @method       	GET
 *  @createdDate    11-OCT-2024
 *  @modifiedDate 	11-OCT-2024
 **/
geofenceAction.getGeofences = async () => {
    try {
        const response = await API({
            url: API_BOOK.GEOFENCE.GET_GEOFENCES.url,
            method: API_BOOK.GEOFENCE.GET_GEOFENCES.method
        })
        return response
    } catch (error) {
        console.log('error', error)
    }
}

geofenceAction.setGeofences = (geofences) => ({
    type: ActionTypes.GEOFENCE.GEOFENCE_LIST_SUCCESS,
    payload: geofences
})

/**
 *  @desc         	Create new geofence
 *  @author      	Amir Shaikh
 *  @api          	/geofence/create
 *  @method       	POST
 *  @createdDate    11-OCT-2024
 *  @modifiedDate 	11-OCT-2024
 **/
geofenceAction.createGeofence = async (payload) => {
    try {
        const response = await API({
            url: API_BOOK.GEOFENCE.CREATE_GEOFENCE.url,
            method: API_BOOK.GEOFENCE.CREATE_GEOFENCE.method,
            data: payload
        })
        return response
    } catch (error) {
        console.log('error', error)
    }
}

/**
 *  @desc         	Get users list
 *  @author      	Amir Shaikh
 *  @api          	/geofence/user-list
 *  @method       	GET
 *  @createdDate    11-OCT-2024
 *  @modifiedDate 	11-OCT-2024
 **/
geofenceAction.getUsers = async () => {
    try {
        const response = await API({
            url: API_BOOK.GEOFENCE.GET_USERS.url,
            method: API_BOOK.GEOFENCE.GET_USERS.method
        })
        return response
    } catch (error) {
        console.log('error', error)
    }
}

/**
 *  @desc         	Create alert configuration
 *  @author      	Amir Shaikh
 *  @api          	/geofence/:fenceID/alerts
 *  @method       	POST
 *  @createdDate    11-OCT-2024
 *  @modifiedDate 	11-OCT-2024
 **/
geofenceAction.createAlerts = async (fenceId,{ fenceRules }) => {
    try {
        const response = await API({
            url: API_BOOK.GEOFENCE.CREATE_ALERTS.url.replace(':fenceID', fenceId),
            method: API_BOOK.GEOFENCE.CREATE_ALERTS.method,
            data: { fenceRules }
        })
        return response
    } catch (error) {
        console.log('error', error)
    }
}

/**
 *  @desc         	Update alert configuration
 *  @author      	Amir Shaikh
 *  @api          	/geofence/:fenceID/alerts
 *  @method       	PUT
 *  @createdDate    11-OCT-2024
 *  @modifiedDate 	11-OCT-2024
 **/
geofenceAction.updateAlerts = async (fenceId,{fenceRules }) => {
    try {
        const response = await API({
            url: API_BOOK.GEOFENCE.UPDATE_ALERTS.url.replace(':fenceID', fenceId),
            method: API_BOOK.GEOFENCE.UPDATE_ALERTS.method,
            data: { fenceRules }
        })
        return response
    } catch (error) {
        console.log('error', error)
    }
}

geofenceAction.bulkValidate = async ({ selectedFile }) => {
  try {
    const FormData = require("form-data");
    const formData = new FormData();
    formData.append("file", selectedFile);
    const response = await API({
      url: API_BOOK.GEOFENCE.BULK_VALIDATE.url,
      method: API_BOOK.GEOFENCE.BULK_VALIDATE.method,
      data: formData,
    });
    return response;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

geofenceAction.reset = () => ({
    type: ActionTypes.GEOFENCE.RESET
})

/**
 *  @desc         	Delete geofence
 *  @author      	Amir Shaikh
 *  @api          	/geofence/:fenceId
 *  @method       	DELETE
 *  @createdDate    26-DEC-2024
 *  @modifiedDate 	26-DEC-2024
 **/
geofenceAction.deleteGeofence = async ({ fenceId }) => {
    try {
        const response = await API({
            url: API_BOOK.GEOFENCE.DELETE_GEOFENCE.url.replace(':fenceID', fenceId),
            method: API_BOOK.GEOFENCE.DELETE_GEOFENCE.method,
        })
        return response
    } catch (error) {
        return error
    }
}

export default geofenceAction